import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { setToken } from './features/auth/authSlice'
import Authentication from './components/Pages/Authentication'
import Layout from './components/Pages/Layout'
import Load from './components/Pages/Load'
import AppInfo from './components/Pages/AppInfo'
import Bots from './components/Pages/Bots'
import Conversations from './components/Pages/Conversations'
import jwt_decode from 'jwt-decode'
import GetStarted from './components/Pages/GetStarted'
import Header from './components/layout/Header/Header'
import Footer from './components/layout/Footer/Footer'
import Summary from './components/Pages/Summary'

const App = ({
  authToken,
  setToken
}) => {

  const validateToken = () => {
    if (authToken) {
      return true
    } else {
      const token = localStorage.getItem('token')
      const tokenData = (token) ? jwt_decode(token) : false
      if (tokenData && new Date(tokenData.exp * 1000) > new Date()) {
        if (tokenData.userInfo.hasOwnProperty('ID') && tokenData.userInfo.hasOwnProperty('RoleID')) {
          setToken({ authToken: token })
          return true
        } else {
          localStorage.clear()
          return false
        }
      } else {
        localStorage.clear()
        return false
      }
    }
  }
  const userLoggedIn = validateToken()
  // console.log(userLoggedIn)
  
  
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        {!userLoggedIn &&
          <Fragment>
            <Route path="*" element={<Auth />} />
            <Route path="getStarted" element={<GetStartedView />} />
            {/* <Route path="getStartedLogin" element={<GetStartedLoginView />} /> */}
          </Fragment>
        }
        {userLoggedIn &&
          <Fragment>
            {/* <Route path="/" element={<AboutApp />} /> */}
            <Route path="/" element={<BotsView />} />
            <Route path="bots" element={<BotsView />} />
            <Route path="conversation" element={<ConversationView />} />
            <Route path="getStarted" element={<GetStartedView />} />
            <Route path="appinfo" element={<AboutApp />} />
            <Route path="load" element={<SelectWebsiteList />} />
            <Route path="summary" element={<SummaryView />} />
            <Route path="*" element={<Auth />} />
          </Fragment>
        }
        </Routes>
      </BrowserRouter>
    </div>
  );
}

function Auth() {
  return (
    <div className="App pt-5">
      <Authentication></Authentication>
    </div>
  )
}

function SelectWebsiteList() {
  return (
    <div className="App">
      <Load></Load>
    </div>
  )
}

function AboutApp() {
  return (
    <div className="App">
      {/* <Layout> */}
      <Header></Header>
        <AppInfo></AppInfo>
      <Footer></Footer>
      {/* </Layout> */}
    </div>
  )
}

function BotsView() {
  return (
    <div className="App">
      <Layout>
        <Bots></Bots>
      </Layout>
    </div>
  )
}

function ConversationView() {
  return (
    <div className="App">
      <Layout>
        <Conversations></Conversations>
      </Layout>
    </div>
  )
}

function GetStartedView() {
  return (
    <div className="App">
    {/* <Layout> */}
      <GetStarted></GetStarted>
    {/* </Layout> */}
    </div>
  )
}

function SummaryView() {
  return (
    <div className="App">
    <Layout>
      <Summary></Summary>
    </Layout>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const {
    authToken
  } = auth

  return {
    authToken
  }
}

const mapDispatchToProps = {
  setToken
}

export default connect(mapStateToProps, mapDispatchToProps)(App)