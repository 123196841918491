import React, { useEffect } from 'react'
import { connect } from "react-redux"
import Footer from '../layout/Footer/Footer'
import Header from '../layout/Header/Header'
import axios from '../../AxiosConfig'
import config from '../../config'
import MainMenuOffcanvas from '../layout/Sidebar/MainMenuOffcanvas'

const Layout = ({
    children
}) => {
  useEffect(() => {
    setInterval(() => {
      let syncOrders = JSON.parse(localStorage.getItem('Orders'))
      let syncPurchase = JSON.parse(localStorage.getItem('Purchase'))
      let syncWastage = JSON.parse(localStorage.getItem('Wastage'))
      let syncCarts = JSON.parse(localStorage.getItem('Carts'))
      let sync = {
        "Orders": syncOrders ,
        "Purchase": syncPurchase,
        "Wastage": syncWastage,
        "Carts": syncCarts
      }
      if (syncOrders === null && syncPurchase === null && syncWastage === null) {
        
      } else {
        axios.put(config.apiUrl + '/Order', sync).then(res=>{
          if (res.data.message === 'Success') {
            Object.keys(res.data.data).map((value, key) => {
              console.log(value,key)
              if (value === 'Orders') {
                console.log(res.data.data[value])
                const newArr = res.data.data[value].filter((v) => v !== 'done')
                console.log(newArr)
                localStorage.setItem('Orders', JSON.stringify(newArr))
              } else if (value === 'Purchase') {
                console.log(res.data.data[value])
                const newArr = res.data.data[value].filter((v) => v !== 'done')
                console.log(newArr)
                localStorage.setItem('Purchase', JSON.stringify(newArr))
              } else if (value === 'Wastage') {
                console.log(res.data.data[value])
                const newArr = res.data.data[value].filter((v) => v !== 'done')
                console.log(newArr)
                localStorage.setItem('Wastage', JSON.stringify(newArr))
              }
            })
          } else {
            // showAppToast({ toastMessage:'Something Went Wrong', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
          }
        })
      }
      
    }, 60000)
  }, [])
    return (
      <div className="App">
        <div className="">
            <div className="row mx-0 px-0">
                <div className="col-12 col-md-12 col-lg-12">
                    <Header></Header>
                    {children}
                    {/* <Footer></Footer> */}
                    <MainMenuOffcanvas></MainMenuOffcanvas>
                </div>
            </div>
        </div>
      </div>
    )
}

const mapStateToProps = () => {
    return {
    }
  }
  
  const mapDispatchToProps = {
  }

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
