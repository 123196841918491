import React from 'react';
import './BotChatFlow.css';


function AddAction({ setAction }) {
    return (
        <div className='AddAction'>
            <button className='btn btn-sm btn-warning' onClick={() => setAction(1)} title='Add Above'>
                <ion-icon name="add-outline"></ion-icon>
            </button>
        </div>
    )
}

function MessageAction({ setAction }) {
    return (
        <div className='messageAction'>
            <button className='btn btn-sm btn-warning' onClick={() => setAction(1)} title='Edit'>
                <ion-icon name="pencil-outline"></ion-icon>
            </button>
            <button className='btn btn-sm btn-danger' title='Delete'>
                <ion-icon name="trash-outline"></ion-icon>
            </button>

            <button className='btn btn-sm btn-secondary' title='Move Up'>
                <ion-icon name="arrow-up-outline"></ion-icon>
            </button>

            <button className='btn btn-sm btn-secondary' title='Move Down'>
                <ion-icon name="arrow-down-outline"></ion-icon>
            </button>
        </div>
    )
}

function BotChatFlow({ setAction, flowId, setCancelAction }) {
    return (
        <>
            <div className="wide-block pt-1 pb-1 position-sticky FilterSectionSection d-flex align-items-center border">
                <button class="btn btn-outline-primary outline-none border-0 btn-sm px-0 d-flex align-items-center justify-content-center d-sm-none" onClick={() => setCancelAction()}>
                    <ion-icon name="chevron-back-outline" class="md hydrated" aria-label="chevron back outline" />
                </button>
                <h3 className='mb-0'>Edit Flow {flowId}</h3>
            </div>
            <div className='ScrollHeader_ve' style={{ '--componentHeight': '100px' }}>


                <div className="message-item bot">
                    <div className="content">
                        <AddAction setAction={setAction} />
                        <div className="bubble">
                            Hi, What is your name?
                        </div>
                        <MessageAction  setAction={setAction} />
                    </div>
                    <img src="assets/img/icon_img/bot.svg" alt="avatar" className="avatar" />
                </div>

                <div className="message-item">
                    <img src="assets/img/icon_img/user.svg" alt="avatar" className="avatar" />

                    <div className="content">
                        <AddAction  setAction={setAction} />
                        <div className="bubble">
                            My Name is John.
                        </div>
                        <MessageAction  setAction={setAction} />
                    </div>
                </div>

                <div className="message-item bot">
                    <div className="content">
                        <AddAction  setAction={setAction} />
                        <div className="bubble">
                            Hi John, Can you please send your registered mobile number?
                        </div>
                        <MessageAction  setAction={setAction} />
                    </div>
                    <img src="assets/img/icon_img/bot.svg" alt="avatar" className="avatar" />
                </div>

                <div className="message-item">
                    <img src="assets/img/icon_img/user.svg" alt="avatar" className="avatar" />

                    <div className="content">
                        <AddAction  setAction={setAction} />
                        <div className="bubble">
                            +91 1234567890
                        </div>
                        <MessageAction  setAction={setAction} />
                    </div>
                </div>

                <div className="message-divider">
                    <div className='backgroundProcess'>OTP Sent</div>
                </div>

                <div className="message-item bot">
                    <div className="content">
                        <AddAction  setAction={setAction} />
                        <div className="bubble">
                        We have sent OTP on your registered mobile number, please send OTP.
                        </div>
                        <MessageAction  setAction={setAction} />
                    </div>
                    <img src="assets/img/icon_img/bot.svg" alt="avatar" className="avatar" />
                </div>

                <div className="message-item">
                    <img src="assets/img/icon_img/user.svg" alt="avatar" className="avatar" />

                    <div className="content">
                        <AddAction  setAction={setAction} />
                        <div className="bubble">
                            123456
                        </div>
                        <MessageAction  setAction={setAction} />
                    </div>
                </div>
                
                <div className="message-divider">
                    <div className='backgroundProcess'>Validating OTP</div>
                </div>

                <div className="message-item bot">
                    <div className="content">
                        <AddAction  setAction={setAction} />
                        <div className="bubble">
                        OTP Validatated successfully, Please send the changes you wish to update.
                        </div>
                        <MessageAction  setAction={setAction} />
                    </div>
                    <img src="assets/img/icon_img/bot.svg" alt="avatar" className="avatar" />
                </div>
                {/* <div className="message-item bot">
                    <div className="content">
                        <div className="title">Marry</div>
                        <div className="bubble">
                            <img src="assets/img/sample/photo/1.jpg" alt="photo" className="imaged w160" />
                        </div>
                        <MessageAction  setAction={setAction} />
                    </div>
                    <img src="assets/img/icon_img/bot.svg" alt="avatar" className="avatar" />
                </div> */}

                <div className="message-item">
                    <img src="assets/img/icon_img/user.svg" alt="avatar" className="avatar" />

                    <div className="content">
                        <AddAction  setAction={setAction} />
                        <div className="bubble">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vitae nisl et nibh iaculis
                            sagittis. In hac habitasse platea dictumst. Sed eu massa lacinia, interdum ex et, sollicitudin elit.
                        </div>
                        <MessageAction  setAction={setAction} />
                    </div>
                </div>
            </div>
            <div className="wide-block p-0 position-sticky b-0 h-50px d-flex align-items-end">
                <button className='w-100 btn btn-warning square'>
                    <ion-icon name="add-outline"></ion-icon>
                    Add in Chat
                </button>
            </div>
        </>
    )
}

export default BotChatFlow