import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { showMainMenuOffcanvas } from '../../../features/Offcanvas/mainMenuOffcanvasSlice'
// import { showMainMenuOffcanvas } from '../../../features/Offcanvas/mainMenuOffcanvasSlice'
import { logout } from '../../../features/auth/authSlice'
import Logo from '../../images/logo.svg'
import NotificationPopup from './NotificationPopup'

const Header = ({
  showMainMenuOffcanvas,
  logout
}) => {
  let navigate = useNavigate()

  const handleMainMenuOffcanvas = () => {
    showMainMenuOffcanvas({ mainMenuOffcanvasVisible: true })
  }

  const handleUserProfile = () => {
    navigate('/userProfile')
  }
  return (
    <div>
      {/* <AppToast
        message={appToastMessage}
        show={appToastVisible}
        background={appToastBackground}
        icon={appToastIcon}
        position={appToastPosition}
      /> */}
      <div className="appHeader">
        <div className="row w-100 d-flex align-items-center">
          <div className="w-50 w-lg-25 d-flex justify-content-start">
            <a href="javascript:void(0)" title='Menu' onClick={handleMainMenuOffcanvas} class="headerButton p-0 d-flex align-items-center">
              <ion-icon name="menu-outline" class="fs-3"></ion-icon>
            </a>

            <h2 className='text-primary px-3 mb-0'><i>EEIA</i></h2>
            {/* <a href="#!" className="headerButton"> */}
            {/* <img src={Logo} className="img-fluid" alt="Brand" width="69x69" /> */}
            {/* </a> */}
          </div>

        </div>
        <div class="right">
          <NotificationPopup />
          {/* <span class="headerButton toggle-searchbox cursor-pointer" onClick={() => logout()} title='Logout'>
              <ion-icon name="log-out-outline" role="img" class="md hydrated" aria-label="search outline"></ion-icon>
          </span> */}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = () => {
  return {
  }
}

const mapDispatchToProps = {
  showMainMenuOffcanvas,
  logout
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header)
