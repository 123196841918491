import React, { useState } from 'react'
import './FlowsList.css';
import { Button, Modal, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'

function FlowsList({ setAction, flowId }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className="wide-block pt-1 pb-1 position-sticky FilterSectionSection d-flex align-items-center">
                <h3 className='mb-0'>Flows</h3>
                {/* <Splide
                    options={{
                        type: "slide",
                        gap: 10,
                        arrows: true,
                        trimSpace: false,
                        autoWidth: true,
                        pagination: false,
                        grid: {
                            rows: 2,
                            cols: 2,
                        }
                    }}
                >
                    <SplideSlide>
                        <span className={`btn btn-sm border-secondary`}>
                            Instagram
                        </span>

                    </SplideSlide>
                    <SplideSlide>
                        <span className={`btn btn-sm border-secondary`}>
                            Whatsapp
                        </span>
                    </SplideSlide>
                    <SplideSlide>
                        <span className={`btn btn-sm border-secondary`}>
                            Facebook
                        </span>
                    </SplideSlide>
                    <SplideSlide>
                        <span className={`btn btn-sm border-secondary`}>
                            Website
                        </span>
                    </SplideSlide>
                </Splide> */}
            </div>
            <div className='FlowsListView ScrollHeader_ve' style={{ '--componentHeight': '100px' }}>
                <ul className="listview simple-listview">
                    {
                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map((item, i) => {
                            return (
                                <li className={item == flowId && 'active'} onClick={() => setAction(item)}>
                                    <div className="item w-100">
                                        <div className="in d-flex justify-content-between">
                                            <div>Flow {item}</div>
                                            {
                                                item == flowId &&
                                                <span className="badge badge-light shadowed"><ion-icon name="chevron-forward-outline"></ion-icon></span>
                                            }
                                        </div>
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className="wide-block p-0 position-sticky b-0 h-50px d-flex align-items-end">
                <button className='w-100 btn btn-primary square' onClick={handleShow}>
                    <ion-icon name="add-outline"></ion-icon>
                    Add Flow
                </button>
            </div>

            <Modal show={show} backdrop="static" onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Flow</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control size="lg" type="text" placeholder="Enter Flow Name" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default FlowsList