import React from 'react';
import './UsersChatList.css';
import { Link } from 'react-router-dom';


function UserListComponent() {
    return (
        <Link to="?chatId=1234" className="item active">
            <img src="assets/img/apps/whatsapp.svg" alt="image" className="image" />
            <div className="in">
                <div>
                    Juan Esteban Sarmiento <span className='timeAgo'>16 min ago</span>
                    <header>Whatsapp</header>
                </div>
                {/* <span className="text-muted">Edit</span> */}
            </div>
        </Link>
    )
}

function UsersChatsList({ }) {
    return (
        <>
            <div className="wide-block pt-1 pb-1 position-sticky searchSection">
                <form className="search-form">
                    <div className="form-group searchbox not-empty">
                        <input type="text" className="form-control" placeholder='Search Conversations' />
                        <i className="input-icon">
                            <ion-icon name="search-outline" role="img" className="md hydrated" aria-label="search outline"></ion-icon>
                        </i>
                    </div>
                </form>
            </div>
            <div className='UsersChatsListView'>
                <ul className="listview image-listview">
                    <li>
                        <Link to="?chatId=1234" className="item active">
                            <img src="assets/img/apps/whatsapp.svg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    Juan Esteban Sarmiento <span className='timeAgo'>16 min ago</span>
                                    <header>Whatsapp</header>
                                </div>
                                {/* <span className="text-muted">Edit</span> */}
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="?chatId=2345" className="item" aria-current="false">
                            <img src="assets/img/apps/instagram.svg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    Monica Ribeiro <span className='timeAgo'>16 min ago</span>
                                    <header>Instagram</header>
                                </div>
                                {/* <span className="text-muted">Edit</span> */}
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="?chatId=3456" className="item">
                            <img src="assets/img/apps/facebook.svg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    Gaspar Antunes <span className='timeAgo'>16 min ago</span>
                                    <header>Facebook</header>
                                </div>
                                {/* <span className="text-muted">Edit</span> */}
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="?chatId=4567" className="item">
                            <img src="assets/img/webweb.svg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    Gaspar Antunes <span className='timeAgo'>16 min ago</span>
                                    <header>Website</header>
                                </div>
                                {/* <span className="text-muted">Edit</span> */}
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="?chatId=5678" className="item">
                            <img src="assets/img/apps/whatsapp.svg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    Juan Esteban Sarmiento <span className='timeAgo'>16 min ago</span>
                                    <header>Whatsapp</header>
                                </div>
                                {/* <span className="text-muted">Edit</span> */}
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="?chatId=6789" className="item">
                            <img src="assets/img/apps/instagram.svg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    Monica Ribeiro <span className='timeAgo'>16 min ago</span>
                                    <header>Instagram</header>
                                </div>
                                {/* <span className="text-muted">Edit</span> */}
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="?chatId=7890" className="item">
                            <img src="assets/img/apps/facebook.svg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    Gaspar Antunes <span className='timeAgo'>16 min ago</span>
                                    <header>Facebook</header>
                                </div>
                                {/* <span className="text-muted">Edit</span> */}
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="?chatId=8901" className="item">
                            <img src="assets/img/webweb.svg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    Gaspar Antunes <span className='timeAgo'>16 min ago</span>
                                    <header>Website</header>
                                </div>
                                {/* <span className="text-muted">Edit</span> */}
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="?chatId=9012" className="item">
                            <img src="assets/img/apps/whatsapp.svg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    Juan Esteban Sarmiento <span className='timeAgo'>16 min ago</span>
                                    <header>Whatsapp</header>
                                </div>
                                {/* <span className="text-muted">Edit</span> */}
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="?chatId=0123" className="item">
                            <img src="assets/img/apps/instagram.svg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    Monica Ribeiro <span className='timeAgo'>16 min ago</span>
                                    <header>Instagram</header>
                                </div>
                                {/* <span className="text-muted">Edit</span> */}
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="?chatId=1234" className="item">
                            <img src="assets/img/apps/facebook.svg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    Gaspar Antunes <span className='timeAgo'>16 min ago</span>
                                    <header>Facebook</header>
                                </div>
                                {/* <span className="text-muted">Edit</span> */}
                            </div>
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default UsersChatsList