import React from 'react'

function AddChatInFlow({ setCancelAction }) {
  return (
    <>
    <div className='pt-4 px-4 pb-0 mb-0 ScrollHeader_ve' style={{'--componentHeight': '50px'}}>
        <div class="form-group basic">
            <label class="form-label" for="Type1">Add / Edit</label>
            <div className='d-flex gap-4'>
                <div class="form-check mb-1">
                    <input class="form-check-input" type="radio" defaultChecked name="flexRadioDefault" id="flexRadioDefault1" />
                    <label class="form-check-label" for="flexRadioDefault1">
                        Input 
                    </label>
                </div>
                <div class="form-check mb-1">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                    <label class="form-check-label" for="flexRadioDefault2">
                        Process
                    </label>
                </div>
                <div class="form-check mb-1">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                    <label class="form-check-label" for="flexRadioDefault3">
                        Response
                    </label>
                </div>
            </div>
        </div>

        <div class="form-group basic">
            <div class="input-wrapper">
                <label class="form-label" for="Type1">Type</label>
                <select class="form-control form-select" id="Type1">
                    <option value="0">Image</option>
                    <option value="1">Text</option>
                </select>
            </div>
        </div>

        <div class="form-group basic">
            <div class="input-wrapper">
                <label class="form-label" for="message4">Message</label>
                <textarea id="message4" placeholder='Enter Message' rows="2" class="form-control"></textarea>
                <i class="clear-input">
                    <ion-icon name="close-circle" role="img" class="md hydrated" aria-label="close circle"></ion-icon>
                </i>
            </div>
        </div>

        <div class="form-group basic">
            <label class="form-label" for="message4">Data</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">#</span>
                </div>
                <input type="text" class="form-control" placeholder="Enter Value" aria-label="Value" aria-describedby="basic-addon1" />
            </div>
        </div>

    </div>
    <div className="wide-block p-0 position-sticky b-0 h-50px d-flex align-items-end">
        <button className='w-100 btn btn-danger square' onClick={() => setCancelAction()}>
            <ion-icon name="close-outline"></ion-icon>
            Cancel
        </button>
        <button className='w-100 btn btn-warning square'>
            <ion-icon name="checkmark-outline"></ion-icon>
            Save
        </button>
    </div>
    </>
  )
}

export default AddChatInFlow