import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { connect } from "react-redux"
import Logo from "../../images/logo.svg"
// import { setInvoiceID, setPaymentType, setBusinessData } from '../../../features/Data/dataSlice'
// import { showOngoingOffcanvas } from '../../../features/Offcanvas/ongoingOffcanvasSlice'
import { hideMainMenuOffcanvas } from '../../../features/Offcanvas/mainMenuOffcanvasSlice'
// import { triggerBusinessdDataCall } from '../../../features/utilities/generalSlice'
// import { showSwitchBusinessModal } from '../../../features/Offcanvas/switchBusinessModalSlice'
// import { showShopStatusModal } from '../../../features/Offcanvas/shopStatusModalSlice'
import { logout } from '../../../features/auth/authSlice'

const CheckoutOffcanvas = ({
    mainMenuOffcanvasVisible,
    hideMainMenuOffcanvas
}) => {
    console.log(mainMenuOffcanvasVisible);
    return (
        <div>
            <Offcanvas show={mainMenuOffcanvasVisible} placement={'start'} style={{transition: '0.5s'}} onHide={() => {hideMainMenuOffcanvas()}}>
                {/* <Offcanvas.Header class="offcanvas-header" style={{paddingTop: '0px', paddingBottom: '0px'}} closeButton>
                    <Offcanvas.Title style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <img src={Logo} className="img-fluid" alt="Brand" width="90px" />
                    </Offcanvas.Title>
                </Offcanvas.Header> */}
                <Offcanvas.Body class="pt-0 offcanvas-body p-0">
                    {/* <div class="profileBox bg-secondary" style={{position: 'inherit'}}>
                        <div class="in py-2">
                            <h1>EEIA Admin</h1>
                        </div>
                    </div> */}
                    <div class="profileBox" style={{position: 'inherit'}}>
                        <div class="image-wrapper">
                            <img src="assets/img/sample/avatar/avatar1.jpg" alt="image" class="imaged rounded" />
                        </div>
                        <div class="in">
                            <strong>Julian Gruber</strong>
                            <div class="text-muted">
                                <ion-icon name="location"></ion-icon>
                                California
                            </div>
                        </div>
                        <a href="#" class="close-sidebar-button" data-bs-dismiss="offcanvas">
                            <ion-icon name="close"></ion-icon>
                        </a>
                    </div>
                    <ul class="listview flush transparent no-line image-listview mt-2">
                        <li>
                            <Link to='/bots' class={'item pt-0 pb-0'} style={{cursor: 'pointer'}} onClick={()=>{hideMainMenuOffcanvas()}}>
                                <div class="icon-box bg-primary">
                                    <ion-icon name="cog-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Bots
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to='/conversation' class={'item pt-0 pb-0'} style={{cursor: 'pointer'}} onClick={()=>{hideMainMenuOffcanvas()}}>
                                <div class="icon-box bg-primary">
                                    <ion-icon name="chatbubbles-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Conversation
                                </div>
                            </Link>
                        </li>
                    </ul>
                    {/* <div style={{overflow: 'auto', height: '72vh'}}>
                        <ul class="listview image-listview flush no-line">
                            <li>
                                <Link to='/userLogin' class={'item pt-0 pb-0'} style={{cursor: 'pointer'}}>
                                    <div class="icon-box bg-transparent">
                                        <ion-icon name="checkmark-circle-outline"></ion-icon>
                                    </div>
                                    <div class="in">
                                        <div>Attendance</div>
                                        <span class="text-muted"></span>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <a href="javascript:void(0)" class={'item pt-0 pb-0'} style={{cursor: 'pointer'}}>
                                    <div class="icon-box bg-transparent">
                                        <ion-icon name="pie-chart-outline"></ion-icon>
                                    </div>
                                    <div class="in">
                                        <div>Reports</div>
                                        <span class="text-muted"></span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div> */}
                    <div class="sidebar-buttons px-3 py-2">
                        {/* <Link to="/storeConfig" class="button">
                            <ion-icon name="settings-outline" class="fs-4"></ion-icon>
                        </Link> */}
                        {/* <a href="javascript:void(0)" class="button" onClick={handleLogout}>
                            <ion-icon name="log-out-outline" class="fs-4"></ion-icon>
                        </a> */}
                        <span class="headerButton toggle-searchbox cursor-pointer text-primary d-flex justify-content-center gap-2 align-items-center" onClick={() => logout()} title='Logout'>
                            <ion-icon name="log-out-outline" role="img" class="md hydrated" style={{'fontSize': '30px'}} aria-label="search outline"></ion-icon>
                            <span>Logout</span>
                        </span>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

const mapStateToProps = ({ data, mainMenu, auth }) => {
    // const {
    //     invoiceCount,
    //     businessData
    // } = data
    const {
        mainMenuOffcanvasVisible
    } = mainMenu
    // const {
    //     authToken
    // } = auth
    return {
        // invoiceCount,
        mainMenuOffcanvasVisible,
        // authToken,
        // businessData
    }
  }
  
  const mapDispatchToProps = {
    // setInvoiceID,
    // setPaymentType,
    // showOngoingOffcanvas,
    hideMainMenuOffcanvas,
    // setBusinessData,
    // triggerBusinessdDataCall,
    // showSwitchBusinessModal,
    // showShopStatusModal,
    logout
  }

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutOffcanvas)