import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import axios from '../../AxiosConfig'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import { showAppToast } from '../../features/utilities/appToastSlice'

const Summary = () => {
    const [transactionData, setTransactionData] = useState({});
    useEffect(() => {
        axios.get('/Transactions').then(res => {
            if (res.data.message === 'Success') {
                console.log(res.data.data);
                setTransactionData(res.data.data);
            } else {
                showAppToast({ toastMessage: 'Something went wrong', background: 'danger', icon: 'close', position: 'center', timeout: 1000 })
            }
        }).catch(err => {
            console.error(err);
            showAppToast({ toastMessage: 'Something went wrong', background: 'danger', icon: 'close', position: 'center', timeout: 1000 })
        });
    }, [])
    return (
        <div id="appCapsule">
            <div class="section mt-2">
                <div class="row">
                    <div class="col-6 col-md-3 col-lg-3 mb-2">
                        <div class="card">
                            <div class="card-body p-1">
                                <div class="row">
                                    <div class="col-4 col-sm-4 col-md-4 col-lg-3 d-flex align-items-center justify-content-center">
                                        <div class="d-flex align-items-center justify-content-center rounded-circle bg-primary" style={{ height: '42px', width: '42px' }}>
                                            <ion-icon name="pricetags-outline" class="fs-5"></ion-icon>
                                        </div>
                                    </div>
                                    <div class="col-8 col-sm-8 col-md-8 col-lg-9">
                                        <p class="card-text m-0">Sale</p>
                                        <h3 class="m-0">₹{transactionData.Sale}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-md-3 col-lg-3 mb-2">
                        <div class="card">
                            <div class="card-body p-1">
                                <div class="row">
                                    <div class="col-4 col-sm-4 col-md-4 col-lg-3 d-flex align-items-center justify-content-center">
                                        <div class="d-flex align-items-center justify-content-center rounded-circle bg-success" style={{ height: '42px', width: '42px' }}>
                                            <ion-icon name="cash-outline" class="fs-5"></ion-icon>
                                        </div>
                                    </div>
                                    <div class="col-8 col-sm-8 col-md-8 col-lg-9">
                                        <p class="card-text m-0">Profit</p>
                                        <h3 class="m-0">₹{transactionData.Profit}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-md-3 col-lg-3 mb-2">
                        <div class="card">
                            <div class="card-body p-1">
                                <div class="row">
                                    <div class="col-4 col-sm-4 col-md-4 col-lg-3 d-flex align-items-center justify-content-center">
                                        <div class="d-flex align-items-center justify-content-center rounded-circle bg-warning" style={{ height: '42px', width: '42px' }}>
                                            <ion-icon name="wallet-outline" class="fs-5"></ion-icon>
                                        </div>
                                    </div>
                                    <div class="col-8 col-sm-8 col-md-8 col-lg-9">
                                        <p class="card-text m-0">Expence</p>
                                        <h3 class="m-0">₹{transactionData.Expense}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-md-3 col-lg-3 mb-2">
                        <div class="card">
                            <div class="card-body p-1">
                                <div class="row">
                                    <div class="col-4 col-sm-4 col-md-4 col-lg-3 d-flex align-items-center justify-content-center">
                                        <div class="d-flex align-items-center justify-content-center rounded-circle bg-secondary" style={{ height: '42px', width: '42px' }}>
                                            <ion-icon name="cube-outline" class="fs-5"></ion-icon>
                                        </div>
                                    </div>
                                    <div class="col-8 col-sm-8 col-md-8 col-lg-9">
                                        <p class="card-text m-0">Orders</p>
                                        <h3 class="m-0">{transactionData.Orders}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section mt-2">
                <div class="row">
                    <div class="col-6 col-md-6 col-lg-6 mb-2">
                        <div class="card">
                            <div class="card-body p-1">
                                GRAPH 1
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-md-6 col-lg-6 mb-2">
                        <div class="card">
                            <div class="card-body p-1">
                                GRAPH 2
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section mb-3 mt-1">
                <Splide
                    options={{
                        type: "slide",
                        perPage: 8,
                        gap: 10,
                        pagination: false,
                        arrows: false,
                        breakpoints: {
                            868: {
                                perPage: 6,
                            },
                            768: {
                                perPage: 4,
                            },
                        }
                    }}
                >
                    <SplideSlide>
                        <div class="card">
                            <div class="card-body p-1 d-flex justify-content-center align-items-center">
                                <h3 class="m-0">Title 1</h3>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div class="card">
                            <div class="card-body p-1 d-flex justify-content-center align-items-center">
                                <h3 class="m-0">Title 2</h3>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div class="card">
                            <div class="card-body p-1 d-flex justify-content-center align-items-center">
                                <h3 class="m-0">Title 3</h3>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div class="card">
                            <div class="card-body p-1 d-flex justify-content-center align-items-center">
                                <h3 class="m-0">Title 4</h3>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div class="card">
                            <div class="card-body p-1 d-flex justify-content-center align-items-center">
                                <h3 class="m-0">Title 5</h3>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div class="card">
                            <div class="card-body p-1 d-flex justify-content-center align-items-center">
                                <h3 class="m-0">Title 6</h3>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div class="card">
                            <div class="card-body p-1 d-flex justify-content-center align-items-center">
                                <h3 class="m-0">Title 7</h3>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div class="card">
                            <div class="card-body p-1 d-flex justify-content-center align-items-center">
                                <h3 class="m-0">Title 8</h3>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div class="card">
                            <div class="card-body p-1 d-flex justify-content-center align-items-center">
                                <h3 class="m-0">Title 9</h3>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div class="card">
                            <div class="card-body p-1 d-flex justify-content-center align-items-center">
                                <h3 class="m-0">Title 10</h3>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div class="card">
                            <div class="card-body p-1 d-flex justify-content-center align-items-center">
                                <h3 class="m-0">Title 11</h3>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div class="card">
                            <div class="card-body p-1 d-flex justify-content-center align-items-center">
                                <h3 class="m-0">Title 12</h3>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div class="card">
                            <div class="card-body p-1 d-flex justify-content-center align-items-center">
                                <h3 class="m-0">Title 13</h3>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div class="card">
                            <div class="card-body p-1 d-flex justify-content-center align-items-center">
                                <h3 class="m-0">Title 14</h3>
                            </div>
                        </div>
                    </SplideSlide>
                </Splide>
            </div>
        </div>
    )
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Summary)
