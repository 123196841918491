import React from 'react';
import { Badge } from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Link } from 'react-router-dom';

function NotificationPopup() {
    return (
        <Dropdown>
            <Dropdown.Toggle variant="outline-primary" className='btn-sm px-2' id="dropdown-basic">
                <ion-icon name="notifications-outline" class="d-block m-auto" style={{'fontSize': '18px'}}></ion-icon>
            </Dropdown.Toggle>

            <Dropdown.Menu className='NotiPopup'>
                <div className='notificationPopup'>
                    <ul className='notificationList'>
                        <li>
                            <Link to="/conversation" className='chatHeader'>
                                {/* <ion-icon name="chevron-back-outline"></ion-icon> */}
                                <img src="assets/img/apps/whatsapp.svg" alt="image" className="image" />
                                <div className="in">
                                    <div>
                                        <span className='UserName'>Juan Esteban Sarmiento</span> 
                                        <span className='h6 d-block fs-10'>2 min ago</span>
                                        <Badge bg="primary">4</Badge>
                                    </div>
                                    {/* <span className="text-muted">Edit</span> */}
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/conversation" className='chatHeader'>
                                {/* <ion-icon name="chevron-back-outline"></ion-icon> */}
                                <img src="assets/img/apps/instagram.svg" alt="image" className="image" />
                                <div className="in">
                                    <div>
                                        <span className='UserName'>Juan Esteban Sarmiento</span> 
                                        <span className='h6 d-block fs-10'>2 min ago</span>
                                        <Badge bg="primary">6</Badge>
                                    </div>
                                    {/* <span className="text-muted">Edit</span> */}
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/conversation" className='chatHeader'>
                                {/* <ion-icon name="chevron-back-outline"></ion-icon> */}
                                <img src="assets/img/apps/facebook.svg" alt="image" className="image" />
                                <div className="in">
                                    <div>
                                        <span className='UserName'>Juan Esteban Sarmiento</span> 
                                        <span className='h6 d-block fs-10'>2 min ago</span>
                                        <Badge bg="primary">3</Badge>
                                    </div>
                                    {/* <span className="text-muted">Edit</span> */}
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
                {/* <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default NotificationPopup