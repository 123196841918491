import { configureStore } from '@reduxjs/toolkit'
import appToastReducer from '../features/utilities/appToastSlice'
import MainMenuOffcanvasSlice from '../features/Offcanvas/mainMenuOffcanvasSlice';
import authReducer from '../features/auth/authSlice'

export const store = configureStore({
  reducer: {
    appToast: appToastReducer,
    auth: authReducer,
    mainMenu: MainMenuOffcanvasSlice
  },
});