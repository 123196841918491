import React from 'react'
import Logo from '../images/logo.svg'
import FlowsList from './Comps/FlowsList';
import { Link, useSearchParams } from 'react-router-dom';
import BotChatFlow from './Comps/BotChatFlow';
import AddChatInFlow from './Comps/AddChatInFlow';

function Bots(props) {
    const [searchParams, setSearchParams] = useSearchParams({ 'botId': '', 'flowId': '', 'msgPosition': '' })

    const botId = searchParams.get('botId');
    const flowId = searchParams.get('flowId');
    const msgPosition = searchParams.get('msgPosition');

    console.log({ botId, flowId, msgPosition })

    const setFlowId = (ID) => {
        if (ID) {
            setSearchParams({ 'botId': botId, 'flowId': ID })
        } else {
            setSearchParams({ 'botId': botId })
        }
    }

    const setChatPosition = (ID) => {
        if (ID) {
            setSearchParams({ 'botId': botId, 'flowId': flowId, 'msgPosition': ID })
        } else {
            setSearchParams({ 'botId': botId, 'flowId': flowId })
        }
    }
    return (
        <>
            <div id="appCapsule">
                <div className="section full">
                    <div className={`row my-3 ${botId.trim().length > 0 ? 'd-none' : ''}`}>
                        <div className='col-md-3 p-1'>
                            <Link to="/bots?botId=1">
                                <div className="card">
                                    <div className="card-header">
                                        Bot 1
                                    </div>
                                    <div className="card-body">
                                        <blockquote className="blockquote mb-0">
                                            <p>3 Flows</p>
                                        </blockquote>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className='col-md-3 p-1'>
                            <Link to="/bots?botId=2">
                                <div className="card">
                                    <div className="card-header">
                                        Bot 2
                                    </div>
                                    <div className="card-body">
                                        <blockquote className="blockquote mb-0">
                                            <p>2 Flows</p>
                                        </blockquote>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className='col-md-3 p-1'>
                            <Link to="/bots?botId=3">
                                <div className="card">
                                    <div className="card-header">
                                        Bot 3
                                    </div>
                                    <div className="card-body">
                                        <blockquote className="blockquote mb-0">
                                            <p>1 Flow</p>
                                        </blockquote>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className='col-md-3 p-1'>
                            <Link to="/bots?botId=4">
                                <div className="card">
                                    <div className="card-header">
                                        Bot 4
                                    </div>
                                    <div className="card-body">
                                        <blockquote className="blockquote mb-0">
                                            <p>4 Flows</p>
                                        </blockquote>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className='col-md-3 p-1'>
                            <Link to="/bots?botId=5">
                                <div className="card">
                                    <div className="card-header">
                                        Bot 5
                                    </div>
                                    <div className="card-body">
                                        <blockquote className="blockquote mb-0">
                                            <p>2 Flows</p>
                                        </blockquote>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className={`row ${botId.trim().length > 0 ? '' : 'd-none'}`}>
                        <div className={`col-12 ${(flowId === '') ? '' : 'd-none'} d-md-block col-md-4 col-lg-3 bg-light px-0`}>
                            <FlowsList setAction={setFlowId} flowId={flowId} />
                        </div>
                        <div className={`col-12 ${(msgPosition !== '') ? 'd-none' : ''} ${(flowId === '') ? 'd-md-none' : 'd-md-block'} col-md-4 col-lg-5 px-0`}>
                            <BotChatFlow setAction={setChatPosition} flowId={flowId} setCancelAction={setFlowId} />
                        </div>
                        <div className={`col-12 ${(msgPosition === null || msgPosition === '') ? 'd-none' : ''} col-md-4 p-0 bg-white col-lg-4`}>
                            <AddChatInFlow setCancelAction={setChatPosition} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Bots